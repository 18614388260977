
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import LoginRegisterMixin from './mixins/LoginRegisterMixin.vue'
import errorHandler from '@/utils/errorHandler'
import { localStore } from '@/utils/localstore'

@Options({
  components: { AppLogoOnly },
  directives: { maska },
})
export default class LoginForm extends mixins(LoginRegisterMixin) {
  form = {
    email: '',
    password: '',
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get requiredRule() {
    const requiredRule = {
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.doLogin()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doLogin() {
    const { email, password } = this.form
    const { dispatch } = this.$store

    if (email && password) {
      // localStore.removeLocalData()
      await dispatch(ACTION_PROFILE.LOGIN, {
        email,
        password,
      }).catch((error: Error) => {
        errorHandler(error)
      })
    }
  }

  redirectAfterLogin() {
    this.goto('home')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onGotoRegister() {
    this.$router.push({ name: 'register' })
  }
}
