
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class PopupDemo extends Vue {
  created() {
    this.initialView()
  }

  initialView() {
    /*eslint-disable */
    ;(window as any)._camach_line_ac = []
    ;(window as any)._camach_line_ac.push(['setAccount', '7KdZLWJAcUFqjosihgTY24'])
    var _protocol = 'https:' == document.location.protocol ? 'https://' : 'http://'
    ;(function () {
      var atag = document.createElement('script')
      atag.type = 'text/javascript'
      atag.async = true
      atag.src = _protocol + 'api.line.poti-poti.io/js/camach.popup.js?v=20220407'
      var s = document.getElementsByTagName('script')[0] as any
      s.parentNode.insertBefore(atag, s)
    })()
  }
}
